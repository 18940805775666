<template>
	<div id="horse_infos">
		<div class="row">
			<div class="col-12 col-sm">
				<i>
					{{ $t("horse.ne_le") }} {{ horseDateNaissance }}, {{ horseSexe }}, {{ horse.horse_age }} {{ $t("horse.ans") }}, {{ horseRobe }}, {{ horseRace }} - {{ horse.tiers_horse_part.tiershorsepart_propriete }}% {{ $t("horse.de_propriete") }}
					<span v-if="presence">
						- <font-awesome-icon :icon="['far', 'map-marker-alt']" /> {{ presence.mouvement_label }}
					</span>
				</i>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
	import Constants from 'Constants'
	import Horse from '@/mixins/Horse.js'
    import ModalConfirm from "GroomyRoot/components/Modals/ModalConfirm";

	export default {
		props:[
			'horse',
			'presence',
			'horse_readonly'
		],
		mixins: [
			Horse,
		],
		components: {
            ModalConfirm
		},
		computed: {
			// Retourne le sexe complet dans la langue actuelle
			horseSexe: function() {
				return this.horse.sexe_label
			},
			horseRace: function() {
				return this.horse.race_label
			},
			horseRobe: function() {
				return this.horse.robe_label
			},
			horseDateNaissance: function() {
				const naissance = Date.parseTz(this.horse.horse_datenaissance)
				return naissance.toLocaleDateString(this.$i18n.locale())
			}
		},
		methods:{
			// Appel de la sous-fenêtre de suppression du cheval
            interactDeleteHorse(data) {
                this.$refs.modal_delete_horse.openModal()
            },
            // Appliquer la suppression du cheval
			async applyDeleteHorse() {
				this.devalideHorse(this.horse.horse_id)
				.then(() => {
					this.successToast()
					this.$router.replace({ name: 'horseListe' })
				})
				.catch(e => {
					console.log("HorseRecap::Error", e)
                    this.$refs.modal_delete_horse.closeModal()
				})
			}
		}
	}
</script>
